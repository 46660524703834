import React from "react";
import Card from "react-bootstrap/Card";
import serviceImage1 from "../assets/img/serviceImage1.png";
import serviceImage2 from "../assets/img/serviceImage2.jpeg";
import serviceImage3 from "../assets/img/serviceImage3.jpeg";
import serviceImage4 from "../assets/img/serviceImage4.png";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";

const Services = () => {
  return (
    <div className="services" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Services</h2>
            <h4>For our Clients</h4>
            <p style={{ textAlign: "center" }}>
              We Offer out Tech Solutions to help startups and businesses and{" "}
              <br />
              Develop their Software Prototypes, websites, apps and custom
              solutions and <br /> we work closely with our client to ensure
              their Success.
            </p>

            <hr />

            <div className="row">
              <div class="col-sm-3">
                <Card style={{ height: "100%" }}>
                  <Card.Img variant="top" src={serviceImage1} />
                  <Card.Body>
                    <Card.Title>Web Development</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <b>Web Development</b>
                    </Card.Subtitle>
                    <Card.Text>
                      <ul>
                        <li>Responsive Design</li>
                        <li>Clean Code</li>
                        <li>Lightning-fast load times</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div class="col-sm-3">
                <Card style={{ height: "100%" }}>
                  <Card.Img variant="top" src={serviceImage2} />
                  <Card.Body>
                    <Card.Title>Web Design</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <b>Web Design</b>
                    </Card.Subtitle>
                    <Card.Text>
                      <ul>
                        <li>Desktop and Mobile Designs</li>
                        <li>Custom Designs</li>
                        <li>Responsive Design</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div class="col-sm-3">
                <Card style={{ height: "100%" }}>
                  <Card.Img variant="top" src={serviceImage3} />
                  <Card.Body>
                    <Card.Title>Backend Development</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <b>Web Backend Development</b>
                    </Card.Subtitle>
                    <Card.Text>
                      <ul>
                        <li>Custom Integrations</li>
                        <li>Database Connection</li>
                        <li>API Integration</li>
                        <li>Secured Websites</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div class="col-sm-3">
                <Card style={{ height: "100%" }}>
                  <Card.Img variant="top" src={serviceImage4} />
                  <Card.Body>
                    <Card.Title>Custom Desktop Application</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Custom Desktop Applications
                    </Card.Subtitle>
                    <Card.Text>
                      <ul>
                        <li>Desktop Application for windows & Android</li>
                        <li>
                          Backend Integration for pdf or Invoice Genaration
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              {/* </Carousel> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
