import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import whatsapp from "../assets/img/whatsapp.svg";
import gmail from "../assets/img/gmail.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" className="logoImg logoImage" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/mohitgupta1539/">
                <img src={navIcon1} alt="Icon" />
              </a>
              <a
                href="https://wa.me/+918393994868?text=I am Interested to Work with you"
                target="_blank"
              >
                <img src={whatsapp} alt="Icon" />
              </a>
              <a
                href="https://www.instagram.com/iammohit.gupta_/"
                target="_blank"
              >
                <img src={navIcon3} alt="Icon" />
              </a>
              <a href="mailto: 23.ece.cgc@gmail.com" target="_blank">
                <img src={gmail} alt="Icon" />
              </a>
            </div>
            <h5>
            &copy; Developed by <b> Mohit Kumar Gupta </b>
            </h5>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
