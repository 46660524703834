import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { TestimonialCard } from "./TestimonialCard";
import { clientsRow1, clientsRow2, clientsRow3 } from "./TestimonialCardData";

const Testimonial = () => {
  return (
    <div className="testimonial" id="testimonial">
      <h2>Testimonial</h2>
      <h4>What Our Clients Say</h4>
      <p>
        Real stories from satisfied clients: How our Tech Solutions Have <br />
        Made a Difference
      </p>
      <hr />
      <div className="row">
        <Carousel>
          <Carousel.Item>
            <div class="row">
              {clientsRow1.map((project, index) => {
                return <TestimonialCard key={index} {...project} />;
              })}
            </div>
          </Carousel.Item>
        </Carousel>

        {/* </Carousel> */}
      </div>
    </div>
  );
};

export default Testimonial;
