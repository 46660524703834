import { Col } from "react-bootstrap";

export const TestimonialCard = ({ review, img, name, post }) => {
  return (
    <div class="col-sm-4">
      <div class="card" className="cardImage">
        <div class="card-body">
          <p class="card-text">{review}</p>
          <img class="card-img-top" src={img} alt="Card image cap" />
          <h5 class="card-title">
            <b>{name}</b>
          </h5>
          <h6>{post}</h6>
        </div>
      </div>
    </div>
  );
};
