import profileImg1 from "../assets/img/profileImg1.png";
import profileImg2 from "../assets/img/profileImg2.png";
import profileImg3 from "../assets/img/profileImg3.png";

export const clientsRow1 = [
  {
    img: profileImg1,
    name: "Lisa Cudrow",
    post: "CEO",
    review:
      "Thankyou for excellent web development services! Your professionalism and commitment to excellence are truly great, and I am grateful for all that you do. Thank you!",
  },
  {
    img: profileImg2,
    name: "Jhon Doe",
    post: "Office Manager",
    review: "I don't often write reviews, but I wanted to give a huge round of appaluse to them for their great customer service, customization, and friendly and professional behaviour. Thank you so much for your help, and I hope to work with you again soon.",
  },
  {
    img: profileImg3,
    name: "Robert Garrison",
    post: "Co-founder",
    review:
      "I made bacck the purchase price in just 48 hours! Thank you for making it pain less, pleasant. The service was execellent. I will refer everyone I know.",
  },
];