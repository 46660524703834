import projectfood from "../assets/img/project-food.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";

export const projectsAll = [
  {
    title: "Food Delivery App",
    description: "To build small food Businesses",
    imgUrl: projectfood,
    projectUrl: "https://long-pink-jellyfish-hat.cyclic.app/",
  },
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projImg2,
  },
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projImg3,
  },
];

export const projectsPersonal = [
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projectfood,
    projectUrl: "https://long-pink-jellyfish-hat.cyclic.app/",
  },
  {
    title: "Portfolio",
    description: "Developed this using ReactJs to show my Portfolio",
    imgUrl: "",
  },
];

export const projectsBusiness = [
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projectfood,
    projectUrl: "https://long-pink-jellyfish-hat.cyclic.app/",
  },
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projImg2,
  },
];

export const projectsStartup = [
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projImg2,
  },
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projImg2,
  },
];

export const projectsECommerce = [
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projImg2,
  },
  {
    title: "Business Startup",
    description: "Design & Development",
    imgUrl: projImg2,
  },
];
